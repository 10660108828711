// preact
import { FunctionComponent } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { route } from 'preact-router'

// stores
import SelfStore from 'stores/self'
import UserStore from 'stores/user'

// components
import Modal from 'components/Modal'
import Input from 'components/Input'
import Button from 'components/Button'
import Select from 'components/Select'
import Message from 'components/Message'

const Auth: FunctionComponent<{
	view?: 'creative' | 'partner'
	action?: 'login' | 'signup'
	isOpen?: boolean
	onClose?: () => void
}> = ({
	view: _view = 'creative', action: _action = 'login', isOpen = false,
	onClose
}) => {
	const selfStore = SelfStore.use()
	const userStore = UserStore.use()

	const [ view, setView ] = useState<'creative' | 'partner'>(_view)
	const [ action, setAction ] = useState<'login' | 'reset' | 'signup'>(_action)

	useEffect(() => {
		if (isOpen && action === 'login' && !selfStore.loading && selfStore.user && selfStore.user.slugs?.[0]) {
			route(`/profile/${selfStore.user.slugs[0]}`)
			onClose && onClose()
		}
	}, [ selfStore.loading, selfStore.user ])

	useEffect(() => {
		if (isOpen && action === 'reset' && !userStore.loading && userStore.success) {
			route(`/?m=New password sent&t=success`)
			onClose && onClose()
		}
	}, [ userStore.loading, userStore.success ])

	const [ email, setEmail ] = useState('')
	const [ password, setPassword ] = useState('')

	useEffect(() => {
		if (isOpen) {
			setView(_view)
			setAction(_action)
		}
		setEmail('')
		setPassword('')
	}, [ isOpen ])

	useEffect(() => {
		setPassword('')
		selfStore.clearErrors()
		userStore.clearErrors()
		userStore.setSuccess(false)
	}, [ action ])

	const onSubmit = (e: Event) => {
		e.preventDefault()
		switch (action) {
			case 'login':
				selfStore.logIn({ email, password })
				break
			case 'reset':
				userStore.recover({ email })
				break
		}
	}
	const onSignUp = async (e: Event) => {
		route(`/onboarding?view=${view}`)
		onClose && onClose()
	}

	return <Modal id="auth" isOpen={isOpen}>
		<header className="auth-header">
			{action && <>
				{action === 'signup' ? <>
					<hgroup>
						<h2 className="auth-title">Sign up</h2>
						<p>&nbsp;as a</p>
					</hgroup>
					<Select name="Account Type" label={view} target="auth"
							select={[ view ]} onSelect={ids => setView(ids[0] as 'creative' | 'partner')}>
						<Select.Option id="creative" value={'Creative'}>Creative</Select.Option>
						<Select.Option id="partner" value={'Partner'}>Partner</Select.Option>
					</Select>
				</> : action === 'login' ? <h2 className="auth-title">Log in</h2> : <h2 className="auth-title">Reset password</h2>}
			</>}
			<Button importance="tertiary" icon={{ glyph: 'close', position: 'center' }} disabled={selfStore.loading} onClick={onClose}>Close</Button>
		</header>
		{action === 'signup' ? <>
					<p className="signup-blurb">
						{view === 'creative' ? 'Showcase your work to a wider audience, connect with potential buyers and collaborators, and gain access to exclusive resources and opportunities to elevate your art career.'
								: 'Connect with a vibrant community of artists, expand your client base, and promote your services to those who need them most. Enjoy increased visibility and opportunities for collaboration.'}
					</p>
					<footer className="signup-footer">
						<Button importance="tertiary" disabled={selfStore.loading} onClick={onClose}>Cancel</Button>
						<Button importance="primary" type="submit" disabled={selfStore.loading} onClick={onSignUp}>Sign up</Button>
					</footer>
				</> :
				<form id="auth-form" name="auth" onSubmit={e => (e.preventDefault(), false)}>
					<Input type="email" name="email" label="Email address" placeholder="youremail@example.com" autocomplete="email"
						   value={email} onInput={setEmail}/>
					{action === 'login' && <Input type="password" name="password" label="Password" autocomplete="new-password"
												  value={password} onInput={setPassword}/>}
					<footer className="signup-footer">
						<Button importance="tertiary" disabled={selfStore.loading}
								onClick={() => setAction(action === 'login' ? 'reset' : 'login')}>{action === 'login' ? 'Forgot password?' : 'Back to log in'}</Button>
						<Button importance="primary" type="submit" disabled={selfStore.loading}
								onClick={onSubmit}>{action === 'login' ? 'Log in' : 'Reset'}</Button>
					</footer>
					<Message type="error">{selfStore.errors?.errors?.error ?? userStore.errors?.errors?.error}</Message>
				</form>}
		<footer className="auth-footer">
			{action === 'signup' && <p>Already have an account?</p>}
			<Button importance="tertiary"
					disabled={selfStore.loading}
					onClick={() => setAction(action === 'login' ? 'signup' : 'login')}
			>{action === 'login' ? 'Create an account' : 'Log in'}</Button>
		</footer>
	</Modal>
}

export default Auth
