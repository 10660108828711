// preact
import { FunctionComponent } from 'preact'


const Page: FunctionComponent<{
	name?: string,
	style?: any
}> = ({ name, style, children }) => {
	return <main id="content" className={name} style={style}>
		{children}
	</main>
}

export default Page
