// preact
import { useState } from 'preact/hooks'

// api
import { request, User, Profile, Link } from './api'

// util
import { Dispatcher, stateId } from 'stores/util'

type Admin = {
	filters: {
		type: 'creative' | 'partner'
		name: string
	}
	data: { user: User, profile: Profile }[]
}

export default class UserStore {

	private static dispatcher: Dispatcher = new Dispatcher()

	static use () {
		const [ loading, setLoading ] = useState<boolean>(false)
		const [ errors, setErrors ] = useState<Errors | null>(null)
		const [ success, setSuccess ] = useState<boolean>(false)
		const [ admin, setAdmin ] = useState<Admin>({ filters: { type: 'creative', name: '' }, data: [] })

		this.dispatcher.on('success', stateId(), (success: boolean) => {
			setSuccess(success)
		})

		return {
			loading,
			errors,
			success,
			admin,
			setSuccess,
			clearErrors: () => setErrors(null),
			list: async ({ type, state, name }: { type: 'creative' | 'partner', state: 'verified' | 'active', name?: string }): Promise<Admin | undefined> => {
				const admin = await request<Admin>({
					route: { source: 'user', action: 'list' },
					param: { type, state, name },
					setLoading,
					setErrors
				})
				if (admin) {
					setAdmin(admin)
				}
				return admin
			},
			state: async ({ state, ids }: { state: 'pending' | 'verified' | 'active', ids: string[] }): Promise<void> => {
				await request<true>({
					route: { source: 'user', action: 'state' },
					param: { state, ids },
					setLoading,
					setErrors
				})
			},
			register: async ({
				type, email, name, title, description, city, categories, links
			}: {
				type: 'creative' | 'partner', email?: string, name?: string
				title?: string, description?: string, city?: string, categories?: string[], links?: Link[]
			}): Promise<boolean> => {
				const success = !!(await request<User>({
					route: { source: 'user', action: 'register' },
					param: { type, email, name, title, description, city, categories, links },
					setLoading,
					setErrors
				}))
				this.dispatcher.get('success')(success)
				return success
			},
			recover: async ({ email }: { email: string }): Promise<boolean> => {
				const success = !!(await request<true>({
					route: { source: 'user', action: 'recover' },
					param: { email },
					setLoading,
					setErrors
				}))
				this.dispatcher.get('success')(success)
				return success
			}
		}
	}
}
