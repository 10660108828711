// preact
import { FunctionComponent } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { route } from 'preact-router'

// stores
import SelfStore from 'stores/self'
import ProfileStore from 'stores/profile'

// components
import Page from 'components/Page'
import Input from 'components/Input'
import Button from 'components/Button'
import Select from 'components/Select'
import Upload from 'components/Upload'
import UserProfile from 'components/UserProfile'
import Gallery, { GalleryEdit, GalleryItem } from 'components/Gallery'
import Message from 'components/Message'

// types
import { Photo } from 'stores/api/types'

// consts
import { LINKS, MEDIUMS, RESOURCES } from 'stores/api/consts'


const Detail: FunctionComponent<{ slug?: string }> = ({ slug: _slug, children }) => {
	const selfStore = SelfStore.use()
	const profileStore = ProfileStore.use()

	useEffect(() => {
		if (_slug) {
			profileStore.get({ slug: _slug })
		}
	}, [ _slug ])

	useEffect(() => {
		if (profileStore.errors?.status === Http.Code.NotFound) {
			route(`/?m=${profileStore.errors.errors?.error ?? 'Profile not found'}&t=error`)
		}
	}, [ profileStore.errors ])

	const user = selfStore.user
	const profile = profileStore.profile
	const isSelf = !!user && !!profile && profile.uid == user.id
	const profileCats = profile?.categories.map(({ id }) => id) ?? []
	const image1 = profile?.photos?.find(({ id }) => id === 'image1')
	const image2 = profile?.photos?.find(({ id }) => id === 'image2')
	const image3 = profile?.photos?.find(({ id }) => id === 'image3')
	const image4 = profile?.photos?.find(({ id }) => id === 'image4')

	const [ open, setOpen ] = useState<string>('')
	const [ editable, setEditable ] = useState<boolean>(false)

	const [ name, setName ] = useState<string>(profile?.name ?? '')
	const [ slug, setSlug ] = useState<string>(profile?.slug ?? '')
	const [ description, setDescription ] = useState<string>(profile?.description ?? '')
	const [ city, setCity ] = useState<string>(profile?.location.city ?? '')
	const [ categories, setCategories ] = useState<string[]>(profileCats)

	const [ website, setWebsite ] = useState<string>(profile?.links.find(link => link.id === LINKS.website.id)?.url ?? '')
	const [ instagram, setInstagram ] = useState<string>(profile?.links.find(link => link.id === LINKS.instagram.id)?.url ?? '')
	const [ youtube, setYoutube ] = useState<string>(profile?.links.find(link => link.id === LINKS.youtube.id)?.url ?? '')
	const [ facebook, setFacebook ] = useState<string>(profile?.links.find(link => link.id === LINKS.facebook.id)?.url ?? '')

	const [ image, setImage ] = useState<File | null>(null)
	const [ photos, setPhotos ] = useState<Record<string, Photo>>({})

	const setProfile = () => {
		setName(profile?.name ?? '')
		setSlug(profile?.slug ?? '')
		setDescription(profile?.description ?? '')
		setCity(profile?.location.city ?? '')
		setCategories(profileCats)
		setWebsite(profile?.links.find(link => link.id === LINKS.website.id)?.url ?? '')
		setInstagram(profile?.links.find(link => link.id === LINKS.instagram.id)?.url ?? '')
		setYoutube(profile?.links.find(link => link.id === LINKS.youtube.id)?.url ?? '')
		setFacebook(profile?.links.find(link => link.id === LINKS.facebook.id)?.url ?? '')
	}

	useEffect(() => {
		setProfile()
		setImage(null)
		setPhotos({})
	}, [ profile ])

	useEffect(() => {
		if (profile && editable && !profileStore.loading && !profileStore.errors) {
			if (profile.slug !== _slug) {
				selfStore.self()
				route(`/profile/${profile.slug}`)
				return
			}
			setEditable(false)
		}
	}, [ profileStore.loading ])

	const shareInit = 'Copy profile link'
	const [ shareLabel, setShareLabel ] = useState<string>(shareInit)

	const copyShare = async () => {
		try {
			if (profile?.slug) {
				await navigator.clipboard.writeText(`https://${window.location.host}/profile/${profile.slug}`)
			} else {
				throw new Error('Failed to copy profile link')
			}
			setShareLabel('Link copied!')
		} catch (err) {
			setShareLabel('Failed to copy profile link')
		}
		setTimeout(() => {
			setShareLabel(shareInit)
		}, 7500)
	}

	const onSave = () => {
		if (profile) {
			const links = []
			if (website) {
				links.push({ id: 'website', url: website })
			}
			if (instagram) {
				links.push({ id: 'instagram', url: instagram })
			}
			if (youtube) {
				links.push({ id: 'youtube', url: youtube })
			}
			if (facebook) {
				links.push({ id: 'facebook', url: facebook })
			}
			profileStore.edit({ uid: profile.uid, type: profile.type },
					{ slug, name, description, city, categories, links },
					{ image: image ?? undefined, photos: Object.values(photos) })
		}
	}

	const onCancel = () => {
		setProfile()
		setImage(null)
		setPhotos({})
		setEditable(false)
	}

	useEffect(() => {
		if (isSelf && editable) {
			onCancel()
		}
	}, [ isSelf ])

	const onImage = (file: File | null) => {
		setImage(file)
	}

	const onPhoto = (photo: Photo) => {
		setPhotos(photos => ({ ...photos, [photo.id]: photo }))
	}

	const mark = Math.floor(Math.random() * 20)
	const b1 = Math.floor(Math.random() * 20)
	const b2 = Math.floor(Math.random() * 20)
	const b3 = Math.floor(Math.random() * 20)
	const b4 = Math.floor(Math.random() * 20)

	return <Page name="detail">
		<header className="detail-header">
			<nav class="detail-header-actions">
				{isSelf ? editable ? <>
							<Button importance="primary" loading={profileStore.loading} onClick={onSave}>Save</Button>
							<Button importance="tertiary" onClick={onCancel}>Cancel</Button>
						</> : <Button importance="primary" onClick={() => setEditable(true)}>Edit Profile</Button>
						: <Button importance="tertiary" icon={{ position: 'start', glyph: 'arrow_back_ios' }} to="/"
						>{profile?.type ? profile.type[0].toUpperCase() + profile.type.slice(1) + 's' : ''}</Button>}
			</nav>
			<Button id="share" onClick={copyShare}>{shareLabel}</Button>
		</header>
		<section class="detail-content" style={{
			'--b1': `var(--${b1})`,
			'--b2': `var(--${b2})`,
			'--b3': `var(--${b3})`,
			'--b4': `var(--${b4})`
		}}>
			<div class="detail-bio">
				{profile && editable ? <>
					<Input name="name" label="Profile name" value={name} onInput={setName}/>
					<Input name="slug" label="Profile URL ID" value={slug} onInput={setSlug}/>
					<Upload name="image" heading="Profile picture" image={`${profile.image}_full.webp`} onImage={onImage}/>
					<Input name="description" type="paragraph" label="Description" onInput={setDescription}>{description}</Input>
					<Input name="city" label="City" value={city} onInput={setCity}/>
					<Select name="categories" type="checkbox" label="Choose up to 3" title="Categories"
							select={categories} onSelect={setCategories}
							open={open} onOpen={setOpen}>
						{Object.values(profile.type === 'creative' ? MEDIUMS : RESOURCES).map(({ id, title }) =>
								<Select.Option id={id} value={title}>{title}</Select.Option>
						)}
					</Select>
					<Input type="url" name={LINKS.website.id} label={LINKS.website.id.capitalize()} placeholder="Personal website URL"
						   value={website} onInput={setWebsite}/>
					<Input type="url" name={LINKS.instagram.id} label={LINKS.instagram.id.capitalize()} placeholder="Instagram profile URL"
						   value={instagram} onInput={setInstagram}/>
					<Input type="url" name={LINKS.youtube.id} label={LINKS.youtube.id.capitalize()} placeholder="YouTube channel URL"
						   value={youtube} onInput={setYoutube}/>
					<Input type="url" name={LINKS.facebook.id} label={LINKS.facebook.id.capitalize()} placeholder="Facebook page URL"
						   value={facebook} onInput={setFacebook}/>
				</> : <>
					<UserProfile profile={profile ?? undefined}/>
					{profile?.type === 'partner' ?
							<a href={`https://www.google.com/maps/place/${profile.location.city.replace(' ', '+')}`}
							   className="detail-location" target={'_blank'}>{profile.location.city}</a>
							: <p class="detail-location">{profile?.location.city}</p>
					}
					{profile?.description &&
							<p class="detail-description" style={{ '--mark': `var(--${mark})` }}>{profile?.description}</p>}
					{profile?.links && <div className="detail-links">
						{profile.links.map(({ url, id }: any) => <a className="detail-link" target="_blank" href={url} data-service={id}/>)}
					</div>}
				</>}
			</div>
		</section>
		{children}
		<Gallery editable={editable}>
			{editable ? <GalleryEdit id="image1" photo={image1} onPhoto={onPhoto}/> :
					<GalleryItem id="image1" photo={image1}/>}
			{editable ? <GalleryEdit id="image2" photo={image2} onPhoto={onPhoto}/> :
					<GalleryItem id="image2" photo={image2}/>}
			{editable ? <GalleryEdit id="image3" photo={image3} onPhoto={onPhoto}/> :
					<GalleryItem id="image3" photo={image3}/>}
			{editable ? <GalleryEdit id="image4" photo={image4} onPhoto={onPhoto}/> :
					<GalleryItem id="image4" photo={image4}/>}
		</Gallery>
		{editable && <Message type="error">{profileStore.errors?.errors?.error}</Message>}
	</Page>
}

export default Detail
