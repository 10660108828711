// preact
import { useState } from 'preact/hooks'

// api
import { request, User } from './api'

// util
import { Dispatcher, stateId } from 'stores/util'


export default class SelfStore {

	private static dispatcher: Dispatcher = new Dispatcher()

	private static self: User | undefined = undefined

	static use () {
		const [ selfing, setSelfing ] = useState<boolean>(true)
		const [ loading, setLoading ] = useState<boolean>(false)
		const [ errors, setErrors ] = useState<Errors | null>(null)
		const [ user, setUser ] = useState<User | undefined>(this.self ? { ...this.self } : undefined)

		this.dispatcher.on('self', stateId(), (user: User | undefined) => {
			setUser(user)
		})

		return {
			selfing,
			loading,
			errors,
			user,
			clearErrors: () => setErrors(null),
			self: async (admin?: boolean): Promise<User | undefined> => {
				this.self = await request<User>({
					route: { source: 'user', action: 'self' },
					param: { admin },
					setLoading: setSelfing
				})
				this.dispatcher.get('self')(this.self)
				return this.self
			},
			edit: async ({ name }: { name: string }): Promise<User | undefined> => {
				this.self = await request<User>({
					route: { source: 'user', action: 'edit' },
					param: { name },
					setLoading,
					setErrors
				})
				this.dispatcher.get('self')(this.self)
				return this.self
			},
			logIn: async ({ email, password, admin }: { email: string, password: string, admin?: boolean }): Promise<User | undefined> => {
				admin = !!admin
				this.self = await request<User>({
					route: { source: 'user', action: 'login' },
					param: { email, password, admin },
					setLoading,
					setErrors
				})
				this.dispatcher.get('self')(this.self)
				return this.self
			},
			logOut: async (): Promise<boolean> => {
				const success = await request<true>({
					route: { source: 'user', action: 'logout' },
					param: {},
					setLoading,
					setErrors
				})
				this.self = undefined
				this.dispatcher.get('self')(this.self)
				return !!success
			}
		}
	}
}
