// preact
import { FunctionComponent } from 'preact'

// components
import Message from 'components/Message'

const Input: FunctionComponent<{
	id?: string
	type?: 'text' | 'date' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'url' | 'paragraph'
	value?: string
	label?: string
	placeholder?: string
	autocomplete?: string
	required?: boolean
	onInput?: (text: string) => void
	error?: string
	[x: string]: any
}> = ({
	id, type = 'text', value,
	label, placeholder, autocomplete, required = false,
	onInput, error, children, ...props
}) => {
	id = id ?? props.name
	const Component = type === 'paragraph' ? 'textarea' : 'input'
	return <div class="input" id={id + '-wrapper'}>
		<Component className="input-field" id={id}
				   type={type === 'paragraph' ? undefined : type}
				   wrap={type === 'paragraph' ? 'hard' : undefined}
				   value={value}
				   placeholder={placeholder}
				   autocomplete={autocomplete}
				   required={required}
				   onInput={e => onInput && onInput((e.target as HTMLInputElement).value)}
				   aria-invalid={!!error}
				   aria-errormessage={!!error ? `${id}-error` : undefined}
				   {...props}>{children}</Component>
		<label for={id} className="input-label">{label}</label>
		<Message type="error">{error}</Message>
	</div>
}

export default Input
