// preact
import { FunctionComponent } from 'preact'

// components
import Tooltip, { useTooltip } from 'components/Tooltip'

export type ButtonTypes = {
	Type: 'button' | 'submit' | 'reset'
	Importance: 'primary' | 'secondary' | 'tertiary'
	Quality: 'good' | 'bad' | 'neutral'
};

const Button: FunctionComponent<{
	id?: string,
	type?: ButtonTypes['Type']
	importance?: ButtonTypes['Importance']
	quality?: ButtonTypes['Quality']
	icon?: { glyph?: string, position?: 'start' | 'end' | 'center' }
	to?: string
	disabled?: boolean
	loading?: boolean
	tooltip?: any
	onClick?: (e: Event) => void
	[x: string]: any
}> = ({
	id, type,
	importance = 'secondary', quality = 'neutral',
	icon, tooltip,
	disabled = false, loading = false,
	to, onClick,
	children, ...props
}) => {
	const Component = to ? 'a' : type || onClick ? 'button' : 'div'

	const { isTooltipVisible, getTooltipBindings } = useTooltip()
	const tooltipBindings = tooltip ? getTooltipBindings() : {}

	return <Component
			id={id}
			className="button"
			type={type ? type : onClick ? 'button' : undefined}
			href={to}
			target={to?.startsWith('http') ? '_blank' : undefined}
			data-importance={importance}
			data-quality={quality}
			data-icon={icon?.glyph}
			data-icon-position={icon?.position ? icon.position : icon?.glyph ? 'end' : undefined}
			disabled={disabled || loading}
			aria-busy={loading}
			onClick={onClick}
			{...tooltipBindings}
			{...props}>
		{icon?.glyph && <span class="button-icon">{icon.glyph}</span>}
		<span class="button-label">
			{children}
		</span>
		{tooltip && <Tooltip children={tooltip} isVisible={isTooltipVisible}/>}
	</Component>
}

export default Button
