// preact
import { FunctionComponent } from 'preact'

const Footer: FunctionComponent = ({ children }) => {
	return <footer id="app-footer">
		<p className="copyright">&copy; 2024 <a href="https://www.abgartgroup.com/" target={'_blank'}>ABG Art
			Group</a></p>
		<nav class="footer-nav">
			{children}
		</nav>
	</footer>
}

export default Footer
