// preact
import { FunctionComponent } from 'preact'
import { useState, useEffect } from 'preact/hooks'

// components
import Button from 'components/Button'
import Message from 'components/Message'

const Upload: FunctionComponent<{
	id?: string
	name?: string
	heading?: string
	error?: string
	image?: string | File
	onImage?: (file: File | null) => void
}> = ({
	id,
	name,
	heading,
	error,
	image: _image,
	onImage: _onImage
}) => {
	const [ image, setImage ] = useState<string | File | null>(null)

	useEffect(() => {
		setImage(_image || null)
	}, [ _image ])

	const onInput = (file: File | null) => {
		setImage(file ?? null)
		_onImage && _onImage(file)
	}

	id = id ?? name
	return <div className="upload">
		{heading && <p className="upload-heading">{heading}</p>}
		{image ? <>
					<img src={image instanceof File ? URL.createObjectURL(image) : image} alt="Uploaded image"/>
					<label htmlFor={id || 'image-upload'}>
						<Button importance={'primary'}>Replace</Button>
					</label>
				</>
				: <label htmlFor={id || 'image-upload'}>
					<Button>Add image</Button>
				</label>
		}
		<input type="file" id={id} name={name} aria-invalid={!!error} aria-errormessage={id + '-error'}
			   onClick={(e: any) => {
				   e.target.value = null
			   }}
			   onInput={(e: InputEvent) => {
				   const files = (e.target as HTMLInputElement)?.files
				   if (files && files[0]) {
					   const file = files[0]
					   onInput(file)
				   }
			   }}/>
		<Message type="error">{error}</Message>
	</div>
}

export default Upload
