// preact
import { FunctionComponent, createRef } from 'preact'
import { useEffect } from 'preact/hooks'

const Modal: FunctionComponent<{
	id?: string, isOpen: boolean
}> = ({
	id, isOpen, children
}) => {
	const ref = createRef<HTMLDialogElement>()
	useEffect(() => {
		const dialog = ref.current

		const handleClickOutside = (event: MouseEvent) => {
			if (dialog && !dialog.contains(event.target as Node)) {
				dialog.close()
			}
		}

		if (dialog) {
			if (isOpen) {
				dialog.showModal()
				document.addEventListener('click', handleClickOutside)
			} else if (dialog.open) {
				dialog.close()
				document.removeEventListener('click', handleClickOutside)
			}
		}

		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [ isOpen ])

	return <dialog className="modal" id={id} ref={ref}>
		{/*<button className="close-button">*/}
		{/*    <span className="button-label">Close Dialog</span>*/}
		{/*</button>*/}
		{isOpen ? children : null}
	</dialog>
}

export default Modal
