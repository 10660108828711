export interface User {
	id: string
	type: 'user' | 'admin'
	state: 'pending' | 'verified' | 'active'
	email: string
	name: string
	slugs?: string[]
}

export type LocationCategory = {
	id: string
	title: string
	description: string
}

export type ProfileLocation = {
	id: 'local' | 'regional' | 'other'
	city: string
}

export type ProfileCategory = {
	id: string
	title: string
}

export type Photo = {
	id: string
	url: string
	title: string
	description: string
	image?: File
}

export type Link = {
	id: string
	url: string
}

export interface Profile {
	uid: string
	type: 'creative' | 'partner'
	state: 'pending' | 'verified'
	slug: string
	name: string

	image: string
	description: string
	location: ProfileLocation
	categories: ProfileCategory[]
	photos: Photo[]
	links: Link[]
}
