// preact
import { FunctionComponent } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'

// components
import Modal from 'components/Modal'
import Input from 'components/Input'
import Upload from 'components/Upload'

// types
import { Photo } from 'stores/api/types'


export const GalleryItem: FunctionComponent<{ id: string, photo?: Photo }> = ({ id, photo }) => {
	const [ show, setShow ] = useState<boolean>(false)

	const ref = useRef<HTMLImageElement>(null)
	useEffect(() => {
		if (ref.current) {
			ref.current.setAttribute('aria-hidden', 'false')
		}
	}, [ photo?.url ])

	const handleImageError = (event: Event) => {
		const target = event.target as HTMLImageElement
		if (target) {
			target.setAttribute('aria-hidden', 'true')
		}
	}

	const accent = Math.floor(Math.random() * 20)
	const Component = photo?.url ? 'button' : 'div'
	return <li role={photo?.url ? undefined : 'presentation'} style={{ '--accent': `var(--${accent})` }}>
		{(photo?.url || photo?.description) &&
				<Component class="gallery-item" onClick={photo.url ? () => {
					if (document.scrollingElement) {
						document.scrollingElement.scrollTop = 0
					}
					setShow(!show)
				} : undefined} aria-controls={'gallery-item-' + id}>
					{photo.url && <img src={`${photo.url}_sml.webp`} alt="" loading="lazy" ref={ref} onError={handleImageError}/>}
					{(photo.title || photo.description) && <div class="gallery-item-content">
						{photo.title && <p class="gallery-item-title">{photo.title}</p>}
						{photo.description && <p className="gallery-item-description">{photo.description}</p>}
					</div>}
				</Component>}
		{photo?.url && <Modal isOpen={show} id={'gallery-item-' + id}>
			<button className="close-button" onClick={() => setShow(false)}>
				<span className="button-label">Close Dialog</span>
			</button>
			<img src={`${photo.url}_full.webp`} alt="" loading="lazy"/>
			{(photo.title || photo.description) && <div class="gallery-item-content">
				{photo.title && <p class="gallery-item-title">{photo.title}</p>}
				{photo.description && <p className="gallery-item-description">{photo.description}</p>}
			</div>}
		</Modal>}
	</li>
}

export const GalleryEdit: FunctionComponent<{
	id: string, error?: string, photo?: Photo, onPhoto?: (photo: Photo) => void
}> = ({
	id, error, photo, onPhoto
}) => {
	const [ title, setTitle ] = useState<string>(photo?.title ?? '')
	const [ description, setDescription ] = useState<string>(photo?.description ?? '')
	const [ image, setImage ] = useState<string | File | undefined>(photo?.url)

	useEffect(() => {
		if (photo) {
			setTitle(photo.title)
			setDescription(photo.description)
			setImage(photo.url)
		}
	}, [ photo ])

	const onImage = (image: File | null) => {
		if (image) {
			setImage(image)
		}
	}

	useEffect(() => {
		if ((photo?.title !== title || photo?.description !== description || photo?.url !== image) && image) {
			onPhoto && onPhoto({
				id,
				title,
				description,
				url: '',
				image: image instanceof File ? image : undefined
			})
		}
	}, [ title, description, image ])

	return <fieldset className="gallery-edit" id={id + '-group'}>
		<Upload name={id} error={error} image={typeof image === 'string' ? `${image}_sml.webp` : image} onImage={onImage}/>
		<div className="gallery-edit-fields">
			<Input label="Title" placeholder="Name of the work" value={title} onInput={setTitle}/>
			<Input type="paragraph" label="Description" placeholder="Description of work" onInput={setDescription}>{description}</Input>
		</div>
	</fieldset>
}

const Gallery: FunctionComponent<{ editable?: boolean }> = ({ editable = false, children }) => {
	return <div class="gallery" role={editable ? 'form' : 'region'}>
		{/*<ul class="gallery-accents" role={"presentation"}>*/}
		{/*    {Array.from({ length: 49 }).map((e, i) => <li/>)}*/}
		{/*</ul>*/}
		<ul class="gallery-items">
			{children}
		</ul>
	</div>
}

export default Gallery
