// preact
import { FunctionComponent } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'

// stores
import ProfileStore from 'stores/profile'

//components
import Page from 'components/Page'
import Button from 'components/Button'
import Input from 'components/Input'
import Select from 'components/Select'
import Pagination from 'components/Pagination'
import UserProfile from 'components/UserProfile'

// types
import { Profile } from 'stores/api'

// consts
import { MEDIUMS, RESOURCES, LOCATIONS } from 'stores/api/consts'


const Image: FunctionComponent<{ image?: string }> = ({ image }) => {

	const ref = useRef<HTMLImageElement>(null)
	useEffect(() => {
		if (ref.current) {
			ref.current.setAttribute('aria-hidden', 'false')
		}
	}, [ image ])

	const handleImageError = (event: Event) => {
		const target = event.target as HTMLImageElement
		if (target) {
			target.setAttribute('aria-hidden', 'true')
		}
	}

	return <li className="list-item-image"
			   role={image ? undefined : 'presentation'}>
		{image && <img src={`${image}_tmb.webp`} alt="" loading="lazy" ref={ref} onError={handleImageError}/>}
	</li>
}

export const ListItem: FunctionComponent<{
	profile: Profile
}> = ({
	profile,
	children
}) => {
	const b1 = Math.floor(Math.random() * 20)
	const b2 = Math.floor(Math.random() * 20)
	const b3 = Math.floor(Math.random() * 20)
	const b4 = Math.floor(Math.random() * 20)

	return <li class="list-item" id={profile.slug}>
		<a href={`/profile/${profile.slug}`} className="list-item-link">
			<UserProfile profile={profile}/>
			<ul class="list-item-images" style={{
				'--b1': `var(--${b1})`,
				'--b2': `var(--${b2})`,
				'--b3': `var(--${b3})`,
				'--b4': `var(--${b4})`
			}}>
				{children}
			</ul>
			{profile.location && <p class="detail-location">
				{profile.location.city}
			</p>}
			<footer class="list-item-footer">
				<p class="list-item-overflow">{!profile.description && 'See '}more</p>
				{profile.description && <p class="list-item-description">{profile.description}</p>}
			</footer>
		</a>
	</li>
}

const List: FunctionComponent<{ onShowAuth: (view: 'creative' | 'partner', action: 'login' | 'signup') => void }> = ({ path, onShowAuth }) => {
	const mobile = window.matchMedia('(max-width: 800px)').matches
	const desktop = window.matchMedia('(min-width: 801px)').matches
	// TODO: make these global, handle resize


	useEffect(() => {
		if (window.location.pathname !== path) {
			window.history.replaceState(null, '', path)
		}
	}, [])

	const profileStore = ProfileStore.use()

	const [ open, setOpen ] = useState<string>('')
	const [ type, setType ] = useState<'creative' | 'partner'>(profileStore.filters.type)
	const [ state, setState ] = useState<'verified' | 'all'>(profileStore.filters.state)
	const [ name, setName ] = useState<string>(profileStore.filters.name ?? '')
	const [ location, setLocation ] = useState<string | undefined>(profileStore.filters.location)
	const [ category, setCategory ] = useState<string | undefined>(profileStore.filters.category)
	const [ showFilters, setShowFilters ] = useState(!mobile)

	useEffect(() => {
		setName('')
		setCategory(undefined)
		profileStore.list({ index: 0 }, { type, state })
	}, [ type ])

	useEffect(() => {
		profileStore.list({ index: 0 }, { type, state, name, location, category })
	}, [ state, name, location, category ])

	const shown = profileStore.profiles?.length
	const total = profileStore.page.total

	const b1 = Math.floor(Math.random() * 20)
	const b2 = Math.floor(Math.random() * 20)
	const b3 = Math.floor(Math.random() * 20)
	const b4 = Math.floor(Math.random() * 20)

	return <Page name="list" style={{
		'--b1': `var(--${b1})`,
		'--b2': `var(--${b2})`,
		'--b3': `var(--${b3})`,
		'--b4': `var(--${b4})`
	}}>
		<h2 class="list-title">{type + 's'} List</h2>
		<section class="list-filters">
			<Select name="type" title="Profile Type" label={type + 's'} target={'list-content'}
					select={[ type ]} onSelect={ids => setType(ids[0] as 'creative' | 'partner')}
					open={open} onOpen={setOpen}>
				<Select.Option id="creative" value={'Creatives'} current={type === 'creative' ? 'page' : undefined}>Creatives</Select.Option>
				<Select.Option id="partner" value={'Partners'} current={type === 'partner' ? 'page' : undefined}>Partners</Select.Option>
			</Select>
			<div class="filter-selectors" id="list-filters" aria-hidden={mobile ? !showFilters : undefined}>
				{mobile && <header class="filter-selectors-header">
					<p>Filter {type}s</p>
					<Button icon={{ glyph: 'close', position: 'center' }} onClick={() => setShowFilters(false)}>Close Filters</Button>
				</header>}
				<Select name="state" title="Verified profiles" label="Select status"
						select={[ state ]} onSelect={ids => setState(ids[0] as 'verified' | 'all')}
						open={open} onOpen={setOpen}
						description={<>
							<p>Verification badges are subject to approval by the RCCD admin however, General requirements are the following</p>
							<ul>
								<li>Direct RCCD collaborator on events, productions, or other community participation.</li>
								<li>Existing creative producer with work included in the Emeryville public art collection</li>
								<li>Official RCCD partner organizations and members.</li>
							</ul>
						</>}>
					<Select.Option id="verified" value={'Verified only'}>Verified only</Select.Option>
					<Select.Option id="all" value={`All ${type}s`}>All {type}s</Select.Option>
				</Select>
				<Select name="location" title="Location"
						select={[ location ?? '' ]} onSelect={ids => setLocation(ids[0])}
						open={open} onOpen={setOpen}>
					{Object.values({ '': { id: '', title: 'All Locations', description: '' }, ...LOCATIONS }).map(({ id, title, description }) =>
							<Select.Option id={id} value={title}>
								<p>{title}</p>
								<small>{description}</small>
							</Select.Option>
					)}
				</Select>
				<Select name="category" title={type === 'creative' ? 'Media types' : 'Resource types'}
						select={[ category ?? '' ]} onSelect={ids => setCategory(ids[0])}
						open={open} onOpen={setOpen}>
					{Object.values({ '': { id: '', title: 'All Types' }, ...(type === 'creative' ? MEDIUMS : RESOURCES) }).map(({ id, title }) =>
							<Select.Option id={id} value={title}>{title}</Select.Option>)}
				</Select>
			</div>
		</section>
		<section id="list-content">
			<header class="list-header">
				<div class="list-header-info">
					<h3 class="list-title">List Items</h3>
					<p class="list-count">{shown > 0 ? `Showing ${shown} of ${total}` : 'No'} {type}s</p>
					{mobile &&
							<Button aria-controls={'list-filters'} aria-expanded={showFilters} onClick={() => setShowFilters(!showFilters)}>Filters</Button>
					}
					<Button onClick={() => onShowAuth && onShowAuth(type, 'signup')}>Join them</Button>
				</div>
				<Input id="search" type="search" label={'Search ' + type + 's'} placeholder={'Search ' + type + 's'}
					   value={name} onInput={setName}/>
			</header>
			{profileStore.profiles.length ? <>
				<ul class="list-items">
					{profileStore.profiles.map(profile => <ListItem profile={profile}>
						{profile.photos.map(({ url }) => (<Image image={url}/>))}
					</ListItem>)}
				</ul>
				<Pagination/>
			</> : <div class="list-empty">
				<p>Expand your filters or check back later</p>
			</div>}

		</section>
	</Page>
}

export default List
