export const stateId = (): string => {
	return `${Date.now()}${Math.round(Math.random() * 1000)}`
}

const emptySet = (data: any) => {
}

export class Dispatcher {
	private callbacks: Map<string, ((data: any) => void) & { callbacks: Map<string, (data: any) => void> }> = new Map()

	public get = (type: string): (data: any) => void => this.callbacks.get(type) ?? emptySet

	public on (type: string, id: string, stateCallback: (state: any) => void) {
		let callbacks = this.callbacks.get(type)
		if (!callbacks) {
			const callbacker: ((data: any) => void) & { callbacks: Map<string, (data: any) => void> } = (data: any) => {
				for (const callback of callbacker.callbacks.values()) {
					callback(data)
				}
			}
			callbacker.callbacks = new Map()
			callbacks = callbacker
		}
		callbacks.callbacks.set(id, stateCallback)
		this.callbacks.set(type, callbacks)
	}
}
