const STATES = Object.freeze({
	Deleted: 'deleted',
	Hidden: 'hidden'
})

export const USERS = Object.freeze({
	TYPES: Object.freeze({
		SYSTEM: 'system',
		ADMIN: 'admin',
		USER: 'user'
	}),
	STATES: Object.freeze({
		...STATES,
		PENDING: 'pending',
		ACTIVE: 'active',
		LOCKED: 'locked' // suspended
	}),
	ROUTES: Object.freeze({
		// public
		STRINGS: { source: 'strings', action: 'select' },        // get strings
		PASSWORD_NEW: { source: 'password', action: 'create' },  // request new password
		PASSWORD_SET: { source: 'password', action: 'update' },  // confirm new password
		SIGN_IN: { source: 'user', action: 'signin' },           // sign in
		// session user
		SELF_GET: { source: 'self', action: 'select' },          // get session user
		SELF_SET: { source: 'self', action: 'update' },          // update session user
		SELF_OUT: { source: 'self', action: 'signout' },         // sign out
		// super/client admin
		USERS_GET: { source: 'users', action: 'select' },        // select user
		USERS_SET: { source: 'users', action: 'update' },        // update user
		USERS_NEW: { source: 'users', action: 'create' },        // create user
		USERS_ALL: { source: 'users', action: 'search' }         // search users
	})
})

import { Link, ProfileCategory, LocationCategory } from './types'

export const LINKS: Record<string, Link> = {
	website: { id: 'website', url: '' },
	instagram: { id: 'instagram', url: 'https://www.instagram.com' },
	youtube: { id: 'youtube', url: 'https://www.youtube.com' },
	facebook: { id: 'facebook', url: 'https://www.facebook.com' }
}

export const MEDIUMS: Record<string, ProfileCategory> = {
	sculptures: {
		id: 'sculptures',
		title: 'Sculptures'
	},
	murals: {
		id: 'murals',
		title: 'Murals'
	},
	painting: {
		id: 'painting',
		title: 'Studio painting'
	},
	graphics: {
		id: 'graphics',
		title: 'Graphic design'
	},
	ceramics: {
		id: 'ceramics',
		title: 'Ceramics'
	},
	dance: {
		id: 'dance',
		title: 'Dance'
	},
	music: {
		id: 'music',
		title: 'Music'
	},
	writing: {
		id: 'writing',
		title: 'Writing and poetry'
	},
	photography: {
		id: 'photography',
		title: 'Photography'
	},
	video: {
		id: 'video',
		title: 'Video production'
	},
	sound: {
		id: 'sound',
		title: 'Sound design'
	},
	interior: {
		id: 'interior',
		title: 'Interior design'
	},
	installation: {
		id: 'installation',
		title: 'Installation'
	},
	animation: {
		id: 'animation',
		title: 'Animation'
	},
	events: {
		id: 'events',
		title: 'Event production'
	},
	lighting: {
		id: 'lighting',
		title: 'Lighting and immersive arts'
	},
	textile: {
		id: 'textile',
		title: 'Textiles'
	},
	print: {
		id: 'print',
		title: 'Printmaking'
	},
	mixed: {
		id: 'mixed',
		title: 'Mixed media'
	},
	other: {
		id: 'other',
		title: 'Other'
	}
}

export const RESOURCES: Record<string, ProfileCategory> = {
	rccd: {
		id: 'rccd',
		title: 'RCCD Partners'
	},
	venue: {
		id: 'venue',
		title: 'Event venue'
	},
	gallery: {
		id: 'gallery',
		title: 'Gallery'
	},
	fabricator: {
		title: 'Fabrication',
		id: 'fabricator'
	},
	consultant: {
		id: 'consultant',
		title: 'Consultants'
	},
	printer: {
		id: 'printer',
		title: 'Printing'
	},
	studio: {
		id: 'studio',
		title: 'Studio space'
	},
	festival: {
		id: 'festival',
		title: 'Festivals'
	},
	shipper: {
		id: 'shipper',
		title: 'Shipping'
	},
	framer: {
		id: 'framer',
		title: 'Framing'
	},
	supplier: {
		id: 'supplier',
		title: 'Supplies'
	},
	developer: {
		id: 'developer',
		title: 'Real estate development'
	},
	architect: {
		id: 'architect',
		title: 'Architects'
	},
	display: {
		id: 'display',
		title: 'Display space'
	},
	interior: {
		id: 'interior',
		title: 'Interior design'
	},
	education: {
		id: 'education',
		title: 'Education resource'
	},
	curator: {
		id: 'curator',
		title: 'Curators'
	},
	press: {
		id: 'press',
		title: 'Press and media'
	},
	other: {
		id: 'other',
		title: 'Other'
	}
}

export const LOCATIONS: Record<'local' | 'regional' | 'other', LocationCategory> = {
	local: {
		id: 'local',
		title: 'Local',
		description: 'Emeryville'
	} as const,
	regional: {
		id: 'regional',
		title: 'Regional',
		description: 'Greater Bay Area'
	} as const,
	other: {
		id: 'other',
		title: 'Other',
		description: 'Everybody Else'
	} as const
} as const

export const LOCAL = [
	'Emeryville'
]

export const CITIES = [
	'Berkeley', 'Oakland', 'Piedmont', 'Alameda', 'Albany',
	'American Canyon', 'Antioch', 'Atherton',
	'Belmont', 'Belvedere', 'Benicia', 'Brentwood', 'Brisbane', 'Burlingame',
	'Calistoga', 'Campbell', 'Clayton', 'Cloverdale', 'Colma', 'Concord', 'Corte Madera', 'Cotati', 'Cupertino',
	'Daly City', 'Danville', 'Dixon', 'Dublin',
	'East Palo Alto', 'El Cerrito',
	'Fairfax', 'Fairfield', 'Foster City', 'Fremont',
	'Gilroy',
	'Half Moon Bay', 'Hayward', 'Healdsburg', 'Hercules', 'Hillsborough',
	'Lafayette', 'Larkspur', 'Livermore', 'Los Altos', 'Los Altos Hills', 'Los Gatos',
	'Martinez', 'Menlo Park', 'Mill Valley', 'Millbrae', 'Milpitas', 'Monte Sereno', 'Moraga', 'Morgan Hill', 'Mountain View',
	'Napa', 'Newark', 'Novato',
	'Oakley', 'Orinda',
	'Pacifica', 'Palo Alto', 'Petaluma', 'Pinole', 'Pittsburg', 'Pleasant Hill', 'Pleasanton', 'Portola Valley',
	'Redwood City', 'Richmond', 'Rio Vista', 'Rohnert Park', 'Ross',
	'San Anselmo', 'San Bruno', 'San Carlos', 'San Francisco', 'San Jose', 'San Leandro', 'San Mateo', 'San Pablo', 'San Rafael', 'San Ramon',
	'Santa Clara', 'Santa Rosa', 'Saratoga', 'Sausalito', 'Sebastopol', 'Sonoma', 'South San Francisco', 'St. Helena', 'Suisun City', 'Sunnyvale',
	'Tiburon', 'Union City', 'Vacaville', 'Vallejo', 'Walnut Creek', 'Windsor', 'Woodside', 'Yountville'
]

export const PLACES = [
	'Acalanes Ridge',
	'Alamo',
	'Alhambra Valley',
	'Allendale',
	'Alto',
	'Alum Rock',
	'Angwin',
	'Ashland',
	'Bay Point',
	'Bayview',
	'Bayview-Montalvin',
	'Bethel Island',
	'Black Point-Green Point',
	'Blackhawk',
	'Blackhawk-Camino Tassajara',
	'Bloomfield',
	'Bodega',
	'Bodega Bay',
	'Bolinas',
	'Boyes Hot Springs',
	'Broadmoor',
	'Buena Vista',
	'Burbank',
	'Byron',
	'Cambrian Park',
	'Camino Tassajara',
	'Carmet',
	'Castle Hill',
	'Castro Valley',
	'Cazadero',
	'Cherryland',
	'Clyde',
	'Contra Costa Centre',
	'Crockett',
	'Deer Park',
	'Diablo',
	'Dillon Beach',
	'Discovery Bay',
	'East Foothills',
	'East Richmond Heights',
	'El Granada',
	'El Sobrante',
	'El Verano',
	'Eldridge',
	'Elmira',
	'Emerald Lake Hills',
	'Fairview',
	'Fetters Hot Springs-Agua Caliente',
	'Forestville',
	'Fruitdale',
	'Fulton',
	'Geyserville',
	'Glen Ellen',
	'Graton',
	'Green Valley',
	'Guerneville',
	'Hartley',
	'Highlands-Baywood Park',
	'Inverness',
	'Jenner',
	'Kensington',
	'Kentfield',
	'Kenwood',
	'Knightsen',
	'La Honda',
	'Ladera',
	'Lagunitas-Forest Knolls',
	'Larkfield-Wikiup',
	'Lexington Hills',
	'Loma Mar',
	'Loyola',
	'Lucas Valley-Marinwood',
	'Marin City',
	'Montalvin Manor',
	'Montara',
	'Monte Rio',
	'Moskowite Corner',
	'Moss Beach',
	'Muir Beach',
	'Nicasio',
	'Norris Canyon',
	'North Fair Oaks',
	'North Gate',
	'North Richmond',
	'Oakville',
	'Occidental',
	'Pacheco',
	'Penngrove',
	'Pescadero',
	'Point Reyes Station',
	'Port Costa',
	'Reliez Valley',
	'Rodeo',
	'Rollingwood',
	'Roseland',
	'Rutherford',
	'Salmon Creek',
	'San Geronimo',
	'San Lorenzo',
	'San Martin',
	'San Miguel',
	'Santa Venetia',
	'Saranap',
	'Sea Ranch',
	'Sereno del Mar',
	'Seven Trees',
	'Shell Ridge',
	'Silverado Resort',
	'Sleepy Hallow',
	'Stanford',
	'Stinson Beach',
	'Strawberry',
	'Sunol',
	'Sunol-Midtown',
	'Tamalpais-Homestead Valley',
	'Tara Hills',
	'Temelec',
	'Timber Cove',
	'Tomales',
	'Valley Ford',
	'Vine Hill',
	'Waldon',
	'West Menlo Park',
	'Woodacre'
]
