// preact
import { useState } from 'preact/hooks'

// api
import { request, Profile, Link, Photo } from './api'

// util
import { Dispatcher, stateId } from 'stores/util'

type Page = {
	index: number
	count: number
	total: number
}

type Filters = {
	type: 'creative' | 'partner'
	state: 'verified' | 'all'
	name?: string
	location?: string
	category?: string
}

interface Profiles {
	page: Page
	filters: Filters
	profiles: Profile[]
}

export default class ProfileStore {

	private static dispatcher: Dispatcher = new Dispatcher()

	private static profiles: Profiles = {
		page: {
			index: 0,
			count: 0,
			total: 0
		},
		filters: {
			type: 'creative',
			state: 'all'
		},
		profiles: []
	}
	private static profile: Profile | undefined

	static use () {
		const [ loading, setLoading ] = useState(false)
		const [ errors, setErrors ] = useState<Errors | null>(null)
		const [ data, setData ] = useState<Profiles>(this.profiles)
		const [ profile, setProfile ] = useState<Profile | undefined>(this.profile)

		this.dispatcher.on('list', stateId(), (data: Profiles) => {
			setData(data)
		})
		this.dispatcher.on('profile', stateId(), (profile: Profile) => {
			setProfile(profile)
		})

		return {
			loading,
			errors,
			page: data.page,
			filters: data.filters,
			profiles: data.profiles,
			profile,
			list: async (
				page?: Omit<Page, 'count' | 'total'> & { count?: number },
				filters?: Filters
			): Promise<Profiles> => {
				page = page ?? data.page
				filters = filters ?? data.filters
				this.profiles = (await request<Profiles>({
					route: { source: 'profile', action: 'list' },
					param: { page, filters },
					setLoading,
					setErrors
				})) ?? {
					page: {
						index: 0,
						count: page.count || 8,
						total: 0
					},
					filters,
					profiles: []
				}
				this.dispatcher.get('list')(this.profiles)
				return this.profiles
			},
			state: async ({ state, ids }: { state: 'pending' | 'verified', ids: string[] }): Promise<void> => {
				await request<true>({
					route: { source: 'profile', action: 'state' },
					param: { state, ids },
					setLoading,
					setErrors
				})
			},
			get: async ({ slug }: { slug: string }): Promise<Profile | undefined> => {
				this.profile = undefined
				this.dispatcher.get('profile')(this.profile)
				this.profile = await request<Profile>({
					route: { source: 'profile', action: 'get' },
					param: { slug },
					setLoading,
					setErrors
				})
				this.dispatcher.get('profile')(this.profile)
				return this.profile
			},
			edit: async ({ uid, type }: { uid: string, type: string }, {
				slug, name, description,
				city, categories, links
			}: {
				slug?: string, name?: string, description?: string
				city?: string, categories?: string[], links?: Link[]
			}, { image, photos }: { image?: File, photos: Photo[] } = { photos: [] }) => {
				setLoading(true)
				let profile = await request<Profile>({
					route: { source: 'profile', action: 'edit' },
					param: { uid, type, fields: { slug, name, description, city, categories, links } },
					setErrors
				})
				if (image) {
					const imageData = new FormData()
					imageData.set('data.type', type)
					imageData.set('profile', image)
					profile = await request<Profile>({
						route: { source: 'profile', action: 'image' },
						param: imageData,
						setErrors
					})
				}
				for (const photo of photos) {
					const imageData = new FormData()
					imageData.set('data.type', type)
					imageData.set(`data.${photo.id}.title`, photo.title)
					imageData.set(`data.${photo.id}.description`, photo.description)
					if (photo.image) {
						imageData.set(photo.id, photo.image)
					}
					profile = await request<Profile>({
						route: { source: 'profile', action: 'image' },
						param: imageData,
						setErrors
					})
				}
				setLoading(false)
				if (profile) {
					this.profile = profile
					this.dispatcher.get('profile')(this.profile)
				}
				return this.profile
			}
		}
	}
}
