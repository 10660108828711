// preact
import { FunctionComponent } from 'preact'
import { useEffect, useRef } from 'preact/hooks'

// api
import { User, Profile } from 'stores/api'

export const Avatar: FunctionComponent<{ image?: string }> = ({ image }) => {
	const left = Math.floor(Math.random() * 20)
	const right = Math.floor(Math.random() * 20)
	// TODO: Don't let them be the same number

	const ref = useRef<HTMLImageElement>(null)
	useEffect(() => {
		if (ref.current) {
			ref.current.setAttribute('aria-hidden', 'false')
		}
	}, [ image ])

	const handleImageError = (event: Event) => {
		const target = event.target as HTMLImageElement
		if (target) {
			target.setAttribute('aria-hidden', 'true')
		}
	}

	return <div class="avatar" style={{ '--left': `var(--${left})`, '--right': `var(--${right})` }}>
		{image && <img src={`${image}_tmb.webp`} alt="" className={'avatar-image'} ref={ref} onError={handleImageError}/>}
	</div>
}

const UserProfile: FunctionComponent<{
	user?: User, profile?: Profile
}> = ({ user, profile }) => {
	const verified = profile?.state === 'verified'
	return <figure class="profile" data-verified={verified}>
		<Avatar image={profile?.image}/>
		<figcaption class="profile-info">
			<p class="profile-title">{profile?.name}</p>
			{user && <p class="profile-name">({user.name})</p>}
			{verified && <p class="sr-only">Verified Profile</p>}
			{profile?.categories && <ul class="profile-categories">
				{profile.categories.map(category => <li className="profile-category">{category.title}</li>)}
			</ul>}
		</figcaption>
	</figure>
}

export default UserProfile
