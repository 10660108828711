import 'defines'

export * from './consts'
export * from './types'

// domain
declare const DOMAIN: string

let host = DOMAIN || window.location.hostname
if (!DOMAIN) {
	let parts = host.split('.')
	if (parts.length > 1 && parts.reduce((b: boolean, p: string) => b && isNaN(Number(p)), true)) {
		const split = parts.shift()!.split('-')
		host = `api${split.length === 2 ? `-${split[1]}` : ''}.${parts.join('.')}`
	} else {
		host = `${host}:8000`
	}
}
export const HOST = `${window.location.protocol}//${host}`

const fetchWithTimeout = (
	route: { source: string, action: string },
	param: { [p: string]: any } | FormData,
	headers: { [p: string]: string } = {},
	{ timeout = 13337, controller = new AbortController() } = {}
) => {
	if (timeout) {
		setTimeout(() => {
			controller.abort()
		}, timeout)
	}

	if (param instanceof FormData) {
		param.set('route.source', route.source)
		param.set('route.action', route.action)
	} else {
		param = Object.filter(param)
	}

	return fetch(HOST, {
		signal: controller.signal,
		method: 'POST',
		mode: 'cors',
		cache: 'no-store',
		credentials: 'include',
		headers: {
			...headers,
			...(!(param instanceof FormData) ? { 'Content-Type': 'application/json; charset=utf-8' } : {}),
			'X-Origin-Path': window.location.pathname,
			'X-Origin-Query': window.location.search
		},
		body: !(param instanceof FormData) ? JSON.stringify({ route, data: param }) : param
	})
}

const _request = <T> ({
	route,
	param = {},
	setLoading,
	setErrors
}: {
	route: { source: string, action: string }
	param: { [p: string]: any } | FormData
	setLoading?: (loading: boolean) => void
	setErrors?: (errors: Errors | null) => void
}) => {
	if (setLoading) {
		setLoading(true)
	}
	if (setErrors) {
		setErrors(null)
	}
	return new Promise<T | undefined>((resolve, reject) => {
		fetchWithTimeout(route, param)
			.then(response => {
				return Promise.all([ { code: response.status, text: response.statusText }, response.json() ])
			})
			.then(([ status, body ]) => {
				if (status.code >= Http.Code.BadInput) {
					throw new Errors(body.errors ?? `${status.code} ${status.text}`, status.code)
				}
				return body
			})
			.catch(error => {
				if (!error.errors) {
					error.errors = { error: 'Network connection failed, are you offline?' }
				}
				if (setErrors) {
					setErrors(error)
				}
			})
			.then(resolve, reject)
			.finally(() => {
				if (setLoading) {
					setLoading(false)
				}
			})
	})
}


const debounces: Map<string, any> = new Map()

export const request = <T> ({
	route,
	param = {},
	setLoading,
	setErrors
}: {
	route: { source: string, action: string }
	param: { [p: string]: any } | FormData
	setLoading?: (loading: boolean) => void
	setErrors?: (errors: Errors | null) => void
}) => {
	return new Promise<T | undefined>((resolve, _) => {
		const id = `${route.source}_${route.action}`
		window.clearTimeout(debounces.get(id))
		debounces.set(id, setTimeout(() => {
			resolve(_request<T>({
				route, param, setLoading, setErrors
			}))
		}, 333))
	})
}
