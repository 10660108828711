/**
 *  Built-In Extensions Declarations
 */
declare global {

	interface FunctionConstructor {
		isFunc (func?: unknown): func is Function
	}

	interface NumberConstructor {
		round (num?: unknown, digits?: number): number
	}

	interface StringConstructor {
		isString (str?: unknown): str is string

		hasString (str?: unknown): str is string

		isInt (str?: unknown): str is string

		isDate (str?: unknown): str is string

		isTimestamp (str?: unknown): str is string

		isBase32 (str?: unknown): str is string

		isBase64 (str?: unknown): str is string

		isName (str?: unknown): str is string

		isEmail (str?: unknown): str is string

		isPhone (str?: unknown): str is string

		isUsername (str?: unknown): str is string

		isPassword (str?: unknown): str is string

		isIdentifier (str?: unknown): str is string

		maskEmail (field: string): string

		maskPhone (field: string): string

		maskEmailOrPhone (field: string): string
	}

	interface String {
		capitalize (): string

		titlecase (): string

		sanitize (): string

		alphanumeric (): string
	}

	interface ObjectConstructor {
		isObject (obj?: unknown): obj is { [p: string]: any }

		hasObject (obj?: unknown): obj is { [p: string]: any }

		map<T, U> (obj: { [key: string]: T }, fn: (entry: [ key: string, val: T ]) => U): { [key: string]: U }

		filter (obj: { [key: string]: any }, key?: string): { [key: string]: any }

		flatten (obj: { [key: string]: any }, key?: string): { [key: string]: any }[]

		stringify (obj: any, ws?: string | number): string
	}

	interface ArrayConstructor {
		isEmpty (arr: unknown): arr is []

		hasArray (arr: unknown): arr is unknown[]

		inflate (arr: any[], key?: string, val?: any, del?: boolean): any
	}

	interface Array<T> {
		difference (arr: T[]): T[]

		intersects (arr: T[]): T[]

		uniques (): T[]

		chunks (size: number): T[][]

		filterMap<U, V = U> (filterFn: (value: T, index: number, array: T[]) => unknown, mapFn: (value: U, index: number, array: T[]) => V): V[]
	}

	interface Math {
		clamp (x: number, min: number, max: number): number

		degrees (radians: number): number

		radians (degrees: number): number
	}

	function isDefined<T> (value?: T | undefined | null): value is T

	function throwError (error: Error): never

	class Errors extends Error {

		readonly code?: string
		readonly status: number
		readonly errors: { [p: string]: any }
		readonly commit?: boolean

		constructor (errors: { [p: string]: any } | string, status: number, code?: string, commit?: boolean)
	}

	namespace Http {

		enum Code {
			Okay = 200,
			Empty = 204,
			BadInput = 400,
			NoSession = 401,
			Forbidden = 403,
			NotFound = 404,
			NotValid = 406,
			TooLarge = 413,
			ServerError = 500
		}

		enum Method {
			Get = 'GET',
			Head = 'HEAD',
			Post = 'POST',
			Put = 'PUT',
			Patch = 'PATCH',
			Delete = 'DELETE',
			Connect = 'CONNECT',
			Options = 'OPTIONS',
			Trace = 'TRACE'
		}
	}

}

/**
 * Function Extensions Definitions
 */
Function.isFunc = (func?: unknown): func is Function => {
	return typeof func === 'function' && func.constructor === Function
}

/**
 * Number Extensions Definitions
 */
Number.round = (num?: unknown, digits?: number) => {
	if (Number.isFinite(num)) {
		const p = Math.pow(10, digits || 0)
		const n = ((num as number) * p) * (1 + Number.EPSILON)
		return Math.round(n) / p
	}
	return NaN
}

/**
 * String Extensions Definitions
 */
const REGEX_INT = /^\d+$/

const REGEX_DATE = /^\d{4}-[01]\d-[0-3]\d$/
const REGEX_TSTMP = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+Z$/

const REGEX_BASE32 = /^[0-9A-HJKMNPQ-TV-Z]+$/
const REGEX_BASE64 = /^[0-9a-zA-Z+/]+$/

const REGEX_NAME = /^[a-zA-Z '-]+$/
const REGEX_PHONE = /^\+[1-9]\d{1,14}$/
const REGEX_EMAIL = /^[a-zA-Z0-9]+(?:[._+-][a-zA-Z0-9]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,63}$/

const REGEX_USERNAME = /^[a-z]+[a-z0-9_-]{3,20}$/                                                               // aws cognito
const REGEX_PASSWORD = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[~`!@#$%^&*()\-_=+\[{\]}\\|;:'",<.>/?]).{8,99}$/ // aws cognito
const REGEX_IDENTIFIER = /^[a-zA-Z0-4]+$/

String.isString = (str?: unknown): str is string => {
	return typeof str === 'string'
}

String.hasString = (str?: unknown): str is string => {
	return typeof str === 'string' && str.length > 0
}

String.isInt = (str?: unknown): str is string => {
	return typeof str === 'string' && REGEX_INT.test(str)
}

String.isDate = (str?: unknown): str is string => {
	return typeof str === 'string' && REGEX_DATE.test(str)
}

String.isTimestamp = (str?: unknown): str is string => {
	return typeof str === 'string' && REGEX_TSTMP.test(str)
}

String.isBase32 = (str?: unknown): str is string => {
	return typeof str === 'string' && REGEX_BASE32.test(str)
}

String.isBase64 = (str?: unknown): str is string => {
	return typeof str === 'string' && REGEX_BASE64.test(str)
}

String.isName = (str?: unknown): str is string => {
	return typeof str === 'string' && REGEX_NAME.test(str)
}

String.isEmail = (str?: unknown): str is string => {
	return typeof str === 'string' && REGEX_EMAIL.test(str)
}

String.isPhone = (str?: unknown): str is string => {
	return typeof str === 'string' && REGEX_PHONE.test(str)
}

String.isUsername = (str?: unknown): str is string => {
	return typeof str === 'string' && REGEX_USERNAME.test(str)
}

String.isPassword = (str?: unknown): str is string => {
	return typeof str === 'string' && REGEX_PASSWORD.test(str)
}

String.isIdentifier = (str?: unknown): str is string => {
	return typeof str === 'string' && REGEX_IDENTIFIER.test(str) && str.length <= 12
}

const firstLast = (value: string, index: number, array: string[]) => (index === 0 || index === array.length - 1) ? value : '•'
const lastFour = (value: string, index: number, array: string[]) => (index >= array.length - 4) ? value : '•'

String.maskEmail = (field: string): string => {
	return field.split('@').flatMap(s => s.split('.')).map((value: string, index: number, array: string[]) => {
		return index === 0 ? [ ...value ].map(firstLast).join('') + '@' :
			index < array.length - 1 ? [ ...value ].map(firstLast).join('') + '.' :
				[ ...value ].join('')
	}).join('')
}

String.maskPhone = (field: string): string => {
	return [ ...field ].map(lastFour).join('')
}

String.maskEmailOrPhone = (field: string): string => {
	return String.isEmail(field) ? String.maskEmail(field) : String.isPhone(field) ? String.maskPhone(field) : ''
}

String.prototype.capitalize = function () {
	return this.charAt(0).toUpperCase() + this.slice(1)
}

String.prototype.titlecase = function () {
	return this.toLowerCase().split(/\s+/).map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ')
}

String.prototype.sanitize = function () {
	return `E'${this.replace(/\\/g, String.raw`\\`).replace(/'/g, String.raw`\'`)}'`
}

String.prototype.alphanumeric = function () {
	return this.replace(/\W/g, '').toLowerCase()
}

/**
 * Object Extensions Definitions
 */
Object.isObject = (obj?: unknown): obj is { [p: string]: any } => {
	return !!obj && typeof obj === 'object' && obj.constructor === Object
}

Object.hasObject = (obj?: unknown): obj is { [p: string]: any } => {
	return !!obj && typeof obj === 'object' && obj.constructor === Object && Object.keys(obj).length > 0
}

Object.map = <T, U> (obj: { [key: string]: T }, fn: (entry: [ key: string, val: T ]) => U): { [key: string]: U } => {
	return Object.entries(obj).reduce<{ [key: string]: U }>((o, [ k, v ]) => (o[k] = fn([ k, v ]), o), {})
}

Object.filter = (obj: { [key: string]: any }, key?: string): { [key: string]: any } => {
	return Object.entries(obj).reduce((o: { [key: string]: any }, [ k, v ]) => (k !== key && v !== undefined) ? (o[k] = v, o) : o, {})
}

Object.flatten = (obj: { [key: string]: any }, key: string = 'id'): { [key: string]: any }[] => {
	return Object.entries(obj).map(([ k, v ]) => (v = { [key]: k, ...v }, v))
}

Object.stringify = (obj: any, ws?: string | number): string => {
	return JSON.stringify(obj, (key, value) => {
		return typeof value === 'bigint' ? String(value) : value
	}, ws)
}

/**
 * Array Extensions Definitions
 */
Array.isEmpty = (arr: unknown): arr is [] => {
	return Array.isArray(arr) && arr.length === 0
}

Array.hasArray = (arr: unknown): arr is unknown[] => {
	return Array.isArray(arr) && arr.length > 0
}

Array.inflate = (arr: any[], key?: string, val?: any, del: boolean = false): any => {
	const cpo = Object.isObject(val)
	const cpa = Array.isArray(val)
	return arr.reduce<{ [k: string]: any }>((obj, itm) => {
		val = cpo ? Object.assign({}, val) : cpa ? [ ...(val as any[]) ] : val
		obj[key ? itm[key] : itm] = val ? val : itm
		if (del && key) {
			delete itm[key]
		}
		return obj
	}, {})
}

Array.prototype.difference = function <T> (this: T[], arr: T[]): T[] {
	return this.filter(val => !arr.includes(val))
}

Array.prototype.intersects = function <T> (this: T[], arr: T[]): T[] {
	return this.filter(val => arr.includes(val))
}

Array.prototype.uniques = function <T> (this: T[]): T[] {
	return [ ...new Set<T>(this) ]
}

Array.prototype.chunks = function <T> (size: number): T[][] {
	const chunks = [] as T[][]
	let index = 0
	while (index < this.length) {
		chunks.push(this.slice(index, size + index))
		index += size
	}
	return chunks
}

Array.prototype.filterMap = function <T, U extends T, V = U> (this: T[], filterFn: (value: T, index: number, array: T[]) => unknown, mapFn: (value: U, index: number, array: T[]) => V): V[] {
	return this.reduce<V[]>((acc, itm, idx) => {
		if (!filterFn(itm, idx, this)) {
			return acc
		}
		const newItem = mapFn(itm as U, idx, this)
		acc.push(newItem)
		return acc
	}, [])
}

/**
 * Math Extensions Definitions
 */
Math.clamp = (x: number, min: number, max: number) => {
	return Math.min(Math.max(x, min), max)
}

Math.degrees = (radians: number) => {
	return radians * 180 / Math.PI
}

Math.radians = (degrees: number) => {
	return degrees * Math.PI / 180
}

/**
 * Built-In Functions Definitions
 */
globalThis.isDefined = <T> (value?: T | null | undefined): value is T => {
	return value !== undefined && value !== null
}

globalThis.throwError = (error: Error): never => {
	throw error
}

/**
 * Errors Class Definition
 */
(globalThis as any).Errors = class Errors extends Error {

	readonly code?: string
	readonly status: number
	readonly errors: { [p: string]: string }
	readonly commit?: boolean

	constructor (errors: { [p: string]: any } | string, status: number, code?: string, commit?: boolean) {
		super()
		this.code = code
		this.status = status
		this.errors = Object.hasObject(errors) ? errors as { [p: string]: any } : String.hasString(errors) ? { error: errors } : {}
		this.message = !this.errors.error ? JSON.stringify(this.errors) : this.errors.error
		this.commit = commit
	}
}

/**
 * HttpCode Enum Definition
 */
enum HttpCode {
	Okay = 200,
	Empty = 204,
	BadInput = 400,
	NoSession = 401,
	Forbidden = 403,
	NotFound = 404,
	NotValid = 406,
	TooLarge = 413,
	ServerError = 500
}

enum HttpMethod {
	Get = 'GET',
	Head = 'HEAD',
	Post = 'POST',
	Put = 'PUT',
	Patch = 'PATCH',
	Delete = 'DELETE',
	Connect = 'CONNECT',
	Options = 'OPTIONS',
	Trace = 'TRACE'
}

(globalThis as any).Http = {
	Code: HttpCode,
	Method: HttpMethod
}

export {}
