// preact
import { FunctionComponent, VNode, isValidElement, cloneElement } from 'preact'
import { useEffect, useState } from 'preact/hooks'

// components
import Button from 'components/Button'

interface SelectComponent<P = {}> extends FunctionComponent<P> {
	Option: typeof Item
}

export const Select: SelectComponent<{
	name: string
	type?: 'checkbox' | 'radio'
	title?: string
	label?: string
	description?: any
	target?: string
	select?: string[]
	onSelect?: (ids: string[]) => void
	open?: string
	onOpen?: (name: string) => void
	children: VNode<typeof Item.defaultProps>[]
}> = ({
	name = 'select',
	type = 'radio',
	title,
	label = 'See options',
	description,
	target,
	select,
	onSelect,
	open: _open,
	onOpen,
	children
}) => {
	const [ open, setOpen ] = useState<boolean>(false)
	useEffect(() => {
		setOpen(_open === name)
	}, [ _open ])

	const options = children
			.filter(child => isValidElement(child) && child.type === Item)
			.map((child, i) => cloneElement(child, {
				name, type, checked: (select ?? []).includes(child.props.id!),
				onClick: (id: string) => {
					if (onSelect) {
						const selects = type === 'checkbox' ? [ ...(select ?? []) ] : [ id ]
						if (type === 'checkbox') {
							const index = selects.findIndex(s => s === id)
							if (index >= 0) {
								selects.splice(index, 1)
							} else if (selects.length < 3) {
								selects.push(id)
							}
						}
						onSelect(selects)
					}
					if (type === 'radio') {
						setOpen(!open)
					}
				}
			}))
	const valueLabel = options.filter(child => child.props.checked)
			.slice(0, 3).map(child => child.props.value).join(', ')

	return <div className="select" id={name} aria-controls={target}>
		<header className="select-header">
			{title && <p class="select-title">{title}</p>}
			{description &&
					<Button importance="tertiary" icon={{ glyph: 'info', position: 'center' }} tooltip={description}>More info</Button>}
		</header>
		<button class="select-trigger" type="button" id={name + '-trigger'} onClick={() => (setOpen(!open), !open && onOpen && onOpen(name))}
				aria-controls={name + '-options'} aria-expanded={open}>{valueLabel || label}</button>
		<ul class="select-options" id={name + '-options'} aria-labelledby={name + '-trigger'} aria-hidden={!open}>
			{options}
		</ul>
	</div>
}

export const Item: FunctionComponent<{
	id: string, value: string, name?: string, type?: 'checkbox' | 'radio'
	icon?: string,
	checked?: boolean, disabled?: boolean
	controls?: string, current?: 'time' | 'page' | 'step' | 'location' | 'date' | undefined
	onClick?: (id: string) => void
}> = ({
	id, name, type = 'radio',
	icon,
	checked, disabled = false,
	controls, current,
	onClick,
	children
}) => {
	return <li key={name + '_' + id} class="select-option" aria-current={current}>
		<input id={name + '_' + id} name={name} type={type} checked={checked} disabled={disabled} onClick={() => onClick && onClick(id)}
			   aria-selected={controls ? checked : undefined} aria-controls={controls}/>
		<label htmlFor={name + '_' + id} data-icon={icon}>{children}</label>
	</li>
}

Select.Option = Item

export default Select
