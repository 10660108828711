// preact
import { FunctionComponent } from 'preact'
import { useEffect, useState } from 'preact/hooks'

// components
import Button from 'components/Button'


const Header: FunctionComponent<{
	title?: string, show?: boolean, onShow?: (show: boolean) => void
}> = ({ title, show: _show, onShow, children }) => {
	const mobile = window.matchMedia('(max-width: 800px)')
	// TODO: Check again on resize

	const [ show, setShow ] = useState(!!_show)

	useEffect(() => {
		setShow(_show ?? !mobile)
	}, [ _show ])

	const onShowClick = () => {
		setShow(!show)
		if (onShow) {
			onShow(!show)
		}
	}

	return <header id="app-header">
		<hgroup className="app-header-brand">
			<a href="/">
				<img className="logo logo-outlined" src="/images/logo-outlined.svg" alt="RCCD" width={100} height={28}/>
			</a>
			<h1 class="app-title">{title}</h1>
		</hgroup>
		{mobile.matches ?
				<Button importance="tertiary" icon={{ position: 'center', glyph: show ? 'close' : 'menu' }}
						onClick={onShowClick}>{show ? 'Hide' : 'Show'} navigation menu</Button> : ''}
		<nav id="header-nav" aria-expanded={!mobile || show}>
			{children}
		</nav>
	</header>
}

export default Header
