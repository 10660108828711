// preact
import { FunctionComponent } from 'preact'
import { useState } from 'preact/hooks'

// store
import ProfileStore from 'stores/profile'

// components
import Button from 'components/Button'

export const PageItem: FunctionComponent<{
	index: number, selected?: boolean, onClick?: (index: number) => void
}> = ({
	index, selected = false, onClick
}) => {
	return <li class="page-item" aria-current={selected ? 'page' : undefined}>
		<Button importance={selected ? 'primary' : 'tertiary'} onClick={() => onClick && onClick(index)}>{index + 1}</Button>
	</li>
}

const Pagination: FunctionComponent<{}> = ({ children }) => {
	const profileStore = ProfileStore.use()

	const onPageClick = (index: number) => {
		if (document.scrollingElement) {
			document.scrollingElement.scrollTop = 0
		}
		profileStore.list({ index })
	}
	const onArrowClick = (next: boolean) => {
		if (document.scrollingElement) {
			document.scrollingElement.scrollTop = 0
		}
		if (!next && profileStore.page.index > 0) {
			profileStore.list({ index: profileStore.page.index - 1 })
		} else if (next && profileStore.page.index < (pageCount - 1)) {
			profileStore.list({ index: profileStore.page.index + 1 })
		}
	}

	const pageCount = Math.ceil(profileStore.page.total / (profileStore.page.count || 1))
	return <div class="pagination">
		<Button importance="tertiary" icon={{ glyph: 'arrow_back_ios', position: 'center' }} disabled={profileStore.page.index <= 0}
				onClick={() => onArrowClick(false)}>Previous Page</Button>
		<ol class="pages-list">
			{Array(pageCount).fill(0).map((_, index) =>
					<PageItem index={index} selected={index === profileStore.page.index} onClick={() => onPageClick(index)}/>
			)}
		</ol>
		<Button importance="tertiary" icon={{ glyph: 'arrow_forward_ios', position: 'center' }} disabled={profileStore.page.index >= pageCount - 1}
				onClick={() => onArrowClick(true)}>Next Page</Button>
	</div>
}

export default Pagination
