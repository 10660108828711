if ((module as any).hot) {
	(module as any).hot.accept()
}

if (process.env.NODE_ENV === 'development') {
	require('preact/debug')
}

//import 'defines'

// preact
import { render } from 'preact'

// stores

// components

import App from './App'

const Index = () => {
	return <App/>
}

render(
	<Index/>,
	document.getElementById('root') as HTMLElement
)
